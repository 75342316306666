import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import EventNav from './components/EventNav';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
const mobile = ReactDOM.createRoot(document.getElementById('mobile'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

mobile.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LfopRkjAAAAANL4sGpZSq_RxnGYRZoeLSp99IHI">
    <GoogleReCaptcha/>
  </GoogleReCaptchaProvider>,
  document.getElementById('app')
);
