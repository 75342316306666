import './App.css';
import React, { useEffect, useState, useCallback } from 'react';
import { TextField , Button, Box, Grid, ButtonGroup, Typography, Modal, InputAdornment, IconButton, Tooltip, Checkbox, FormControlLabel } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { db, auth } from './firebase';
import { onValue, ref, set, child, get } from 'firebase/database';
import { signInAnonymously } from "firebase/auth";
import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import emailjs from '@emailjs/browser';
import { validate } from 'react-email-validator';
import uniqid from 'uniqid';
import AppTheme from './components/Theme';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import EventIcon from '@mui/icons-material/Event';

const mainTheme = AppTheme()

const ModelStyle = {
  overflow: 'scroll',
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '75%',
  width: '75%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  p: 4,
};

function App() {

  const eventInitialState = {
    Event_ID: '',
    Send: false,
    Email:''
  }

  const tripInitialState = {
    Event_Type: '',
    Event_Departure_Date: '',
    Event_Departure_City: '',
    Event_Destination: '',
    Event_Duration: '',
    Event_Service: '',
    Event_Service_Link: '',
    Event_Description: 'I have always wanted to make a friends and family trip happen, but something always comes up. So I have decided that I still want to go on these trips, but instead of trying to make it a group activity it will just be a destination. Each of us pays our own way. You can join me in what I want to do or do your own thing. There is no obligation to spend every second with eachother.',
    Event_RSVP: 'Contact the fucker who gave you the Event ID to RSVP or just fucking show up. We do not fucking care.',
    Event_Plan: ''
  }

  const urlParams = window.location.pathname;

  const [EventConfig, setEvent] = useState(eventInitialState);

  const [TripDetails, createTrip] = useState(tripInitialState);

  const [form, displayForm] = useState(false);

  const [active, displayActive] = useState(false);

  const [search, setSearch] = useState(false);

  const [current, displayCurrent] = useState([])

  const refID = EventConfig.Event_ID.toLocaleUpperCase();
  
  const searchRef = ref(db, 'Events/' + refID);

  const databaseRef = ref(db, 'Events/');

  const resetData = () =>{
    createTrip(tripInitialState);
    setEvent(eventInitialState);
  }

  const searchEvent = () => {
    if (EventConfig.Event_ID.length > 1){
      onValue(searchRef, (snapshot) => {
        const data = snapshot.val();
        if (data !== null){
          createTrip(data)
          setSearch(true);
        }else{
          resetData();
          alert("Event ID not found. Please try again or contact the fucker who gave you the code to verify.")
        }
      });  
    }else{
      resetData();
      alert("Event ID not found. Please try again or contact the fucker who gave you the code to verify.");
    }
  };

  useEffect(() => {
    signInAnonymously(auth);

    if (urlParams !== "/"){
      setEvent(previousState => {
        return { ...previousState, Event_ID:urlParams.replace('/','').toLocaleUpperCase().replace("%20", " ")}
      });
      window.history.pushState({}, document.title, "/")
      let tempID = urlParams.replace('/','').toLocaleUpperCase().replace("%20", " ");
      console.log(tempID)
      get(child(databaseRef, tempID)).then((snapshot) => {
        let data = snapshot.val();
        if (data !== null){
          createTrip(data)
          setSearch(true);
        }else{
          resetData();
          alert("Event ID not found. Please try again or contact the fucker who gave you the code to verify.")
        }
      })
    }

  }, [])

  const generateRandomID = () => {
    let temp = uniqid()
    setEvent(previousState => {
      return { ...previousState, Event_ID:temp}
    });

  }

  const handleFormOpen = () => displayForm(true);

  const handleClose = () => {
    displayForm(false);
    setSearch(false); 
    displayActive(false);
    resetData();
  };

  const pushEvent = () => {

    let sendData = {
      "toEmail": EventConfig.Email,
      "eventID": EventConfig.Event_ID
    }

    if(EventConfig.Event_ID === ""){
      alert("Please enter an Event ID.")
    }
    else{
      get(child(databaseRef, refID)).then((snapshot) => {
        if (snapshot.exists() === false){
          if (EventConfig.Send === true){
            if (validate(EventConfig.Email) === true){
              emailjs.send('service_xekc45t', 'template_t3jys0w', sendData, 'wmA9lE_tYuEL4JgX6')
              set(searchRef, TripDetails);
              alert("Event Created");
              displayForm(false);
              setSearch(true);
            }else{
              alert("Please enter a valid email address.")
            }
          }else{
            set(searchRef, TripDetails);
            alert("Event Created");
            displayForm(false);
            setSearch(true);
          }
        }else{
          alert("Event ID already in use. Please create a unique ID or use the random ID option.")
        }
      })
    }
  }

  const handleChange = (event) => {
      
    let field_name = event.currentTarget.name;
    let field_id = event.currentTarget.id;
    let field_value = event.currentTarget.value;
    let field_chcked = event.currentTarget.checked;

    if (field_name === "EventConfig"){
      setEvent(previousState => {
        return { ...previousState, [field_id]:field_value}
      });
    }

    if (field_name === "TripDetails"){
      createTrip(previousState => {
        return { ...previousState, [field_id]:field_value}
      });
    }

    if (field_id === "Send"){
      setEvent(previousState => {
        return { ...previousState, [field_id]:field_chcked}
      });
    }

  };

  return (
    <div className="App">

      <ThemeProvider theme={mainTheme}>
        <Box id="Navigation">
          <Grid 
              container
              justifyContent="center"
              alignItems="center"
          >
            <Grid xs={12}>
              <TextField
                id="Event_ID"
                name="EventConfig"
                label="Event ID"
                inputProps={{ maxLength: 8 }}
                variant="standard"
                margin="normal"
                size="small"
                value={EventConfig.Event_ID}
                onChange={handleChange}
              />
            </Grid>
            <ButtonGroup variant="contained">
                <Button 
                  variant="contained"
                  color="secondary"
                  onClick={searchEvent}
                  endIcon={<SearchIcon />}>
                  Search
                </Button>
                <Button
                  variant="contained"
                  onClick={handleFormOpen}
                  endIcon={<AddIcon />}> 
                  Create New Event
                </Button>
                
            </ButtonGroup>            
            
          </Grid>
        </Box>

        {/* Create Event Form */}
        <Modal
          open={form}
          onClose={handleClose}
          aria-labelledby="modal-create-form"
          aria-describedby="modal-create-description"
        >
          <Box sx={ModelStyle}>

            <Typography id="modal-create-form" variant="h6" component="h2" class="w3-center">
              Create A New<span class="w3-hide-small"> Fucking</span> Event!
            </Typography>

            <Typography id="modal-create-description" class="w3-center" sx={{ mt: 2 }}>

              <TextField 
                  id="Event_ID" 
                  label="Event ID" 
                  variant="outlined"
                  margin="normal"
                  name="EventConfig" 
                  size="small"
                  inputProps={{ maxLength: 8 }}
                  value={EventConfig.Event_ID}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <Tooltip disableFocusListener title="Generate Random Event ID." arrow placement="bottom-end">
                        <InputAdornment position="end">
                          <IconButton edge="end" color="primary" onClick={generateRandomID}>
                            <ShuffleIcon />
                          </IconButton>
                        </InputAdornment>
                      </Tooltip>
                    ),
                  }}
                />

              <Tooltip disableFocusListener title="We are going on a... e.g (Roadtrip, Cruise, Adventure, etc.)" placement="bottom-start">
                <TextField 
                  id="Event_Type" 
                  label="Event Type" 
                  variant="outlined"
                  margin="normal"
                  name="TripDetails" 
                  size="small"
                  value={TripDetails.Event_Type}
                  onChange={handleChange}
                  fullWidth 
                />
              </Tooltip>

              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 0,
                  gridTemplateColumns: 'repeat(2, 1fr)',
                }}
              >
                <Tooltip disableFocusListener title="When are you leaving?" placement="bottom-start">
                  <TextField 
                  id="Event_Departure_Date" 
                  label="Departure Date" 
                  variant="outlined"
                  margin="normal"
                  name="TripDetails" 
                  size="small"
                  value={TripDetails.Event_Departure_Date}
                  onChange={handleChange}
                  fullWidth 
                  />
                </Tooltip>

                <Tooltip disableFocusListener title="Where are you leaving from?" placement="bottom-start">
                  <TextField 
                    id="Event_Departure_City" 
                    label="Departure City" 
                    variant="outlined"
                    margin="normal"
                    name="TripDetails" 
                    size="small"
                    value={TripDetails.Event_Departure_City}
                    onChange={handleChange}
                    fullWidth 
                  />
                </Tooltip>

                <Tooltip disableFocusListener title="How long will you be gone?" placement="bottom-start">
                  <TextField 
                    id="Event_Destination" 
                    label="Destination" 
                    variant="outlined" 
                    margin="normal"
                    name="TripDetails" 
                    size="small"
                    value={TripDetails.Event_Destination}
                    onChange={handleChange}
                    fullWidth 
                  />
                </Tooltip>

                <Tooltip disableFocusListener title="Where are we going?" placement="bottom-start">
                  <TextField 
                    id="Event_Duration" 
                    label="Duration" 
                    variant="outlined" 
                    margin="normal"
                    name="TripDetails" 
                    size="small"
                    value={TripDetails.Event_Duration}
                    onChange={handleChange}
                    fullWidth 
                  />
                </Tooltip>

                <Tooltip disableFocusListener title="Does anything need to be booked or reserved in advance?" placement="bottom-start">
                  <TextField 
                    id="Event_Service" 
                    label="Service (Hotel, Curiseline, Railline, etc.)"
                    variant="outlined"
                    margin="normal"
                    name="TripDetails" 
                    size="small"
                    value={TripDetails.Event_Service}
                    onChange={handleChange}
                    fullWidth 
                  />
                </Tooltip>

                <Tooltip disableFocusListener title="Link to the booking / reservation / service site." placement="bottom-start">
                  <TextField 
                    id="Event_Service_Link" 
                    label="Service Link" 
                    variant="outlined"
                    margin="normal"
                    name="TripDetails" 
                    size="small"
                    value={TripDetails.Event_Service_Link}
                    onChange={handleChange}
                    fullWidth 
                  />
                </Tooltip>

              </Box>

              <Tooltip disableFocusListener title="Discribe the trip. Give me the sales pitch." placement="bottom-start">
                <TextField 
                  id="Event_Description" 
                  label="Description" 
                  variant="outlined"
                  margin="normal"
                  name="TripDetails" 
                  size="small"
                  fullWidth
                  multiline
                  minRows={4} 
                  value={TripDetails.Event_Description}
                  onChange={handleChange}
                />
              </Tooltip>

              <Tooltip disableFocusListener title="How do we participate?" placement="bottom-start">
                <TextField 
                  id="Event_RSVP" 
                  label="RSVP" 
                  variant="outlined"
                  margin="normal"
                  name="TripDetails" 
                  size="small"
                  fullWidth
                  multiline
                  minRows={4} 
                  value={TripDetails.Event_RSVP}
                  onChange={handleChange}
                />
              </Tooltip>

              <Tooltip disableFocusListener title="What are your plans / itinerary?" placement="bottom-start">
                <TextField 
                  id="Event_Plan" 
                  label="My Plan" 
                  variant="outlined"
                  margin="normal"
                  name="TripDetails" 
                  size="small"
                  fullWidth
                  multiline
                  minRows={4} 
                  value={TripDetails.Event_Plan}
                  onChange={handleChange}
                />
              </Tooltip>

              <TextField 
                type="email"
                id="Email" 
                label="Email" 
                variant="outlined"
                margin="normal"
                name="EventConfig" 
                size="small"
                fullWidth
                onChange={handleChange}
              />

              <FormControlLabel
                control={<Checkbox 
                  id="Send"
                  name="EventConfig"/>}
                label="Send Confirmation Email?"
                labelPlacement="start"
                onChange={handleChange}
              />
              
              <br></br>
              <br></br>


              <ButtonGroup variant="contained">
                <Button 
                  variant="contained" 
                  color="success"
                  onClick={pushEvent}
                  endIcon={<AddIcon />}>
                  Create
                </Button>
                <Button 
                  variant="contained" 
                  color="error"
                  onClick={handleClose}
                  endIcon={<CancelIcon />}>
                  Cancel
                </Button>
              </ButtonGroup>

            </Typography>

          </Box>

        </Modal>

        {/* Event Details Display */}
        <Modal
          open={search}
          onClose={handleClose}
          aria-labelledby="modal-trip-title"
          aria-describedby="modal-trip-description"
        >
          <Box sx={ModelStyle}>
            <Typography id="modal-trip-title" variant="h6" component="h2" class="w3-center">
              The<span class="w3-hide-small"> Fucking</span> Plan!
            </Typography>
            <Typography id="modal-trip-description" sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid xs={12}>

                  <p class="w3-center w3-text-orange"><b>Event ID:</b> {EventConfig.Event_ID}</p>
                  <p class="w3-center"><em>We are going on a<span class="w3-hide-small"> fucking</span> {TripDetails.Event_Type}!</em></p>
                  <p class="w3-center w3-small">{TripDetails.Event_Description}</p>
                  <hr></hr>
                </Grid>
                <Grid xs={6}>
                  <p class="w3-center"><b>The<span class="w3-hide-small"> Fucking</span> Details</b></p>
                  <p><i class="fa fa-calendar-day w3-margin-right fa-fw"></i>Departure Date: <span class="w3-text-orange">{TripDetails.Event_Departure_Date}</span></p>
                  <p><i class="fas fa-city w3-margin-right fa-fw"></i>Departure City: <span class="w3-text-orange">{TripDetails.Event_Departure_City}</span></p>
                  <p><i class="fas fa-globe-americas w3-margin-right fa-fw"></i>Destination: <span class="w3-text-orange">{TripDetails.Event_Destination}</span></p>
                  <p><i class="fas fa-clock w3-margin-right fa-fw"></i>Duration: <span class="w3-text-orange">{TripDetails.Event_Duration}</span></p>
                  <p><i class="fas fa-paper-plane w3-margin-right fa-fw"></i>Service: <span class="w3-text-orange">{TripDetails.Event_Service}</span></p>
                  <p><i class="fas fa-link w3-margin-right fa-fw"></i>Link: {TripDetails.Event_Service_Link.length > 1 ? <span class="w3-text-orange"><a class="w3-center w3-small" href={TripDetails.Event_Service_Link} target="_blank">Click Here</a></span>: <div></div>}</p>

                </Grid>
                <Grid xs={6}>
                  <p class="w3-center">My<span class="w3-hide-small"> Fucking</span> Plan</p>
                  <p>{TripDetails.Event_Plan}</p>
                </Grid>
                <Grid xs={12}>
                  <h5 class="w3-center">You<span class="w3-hide-small"> Fucking</span> Going!?</h5>
                  <p class="w3-center w3-small">{TripDetails.Event_RSVP}</p>
                  
                </Grid>
                <Grid xs={12}>
                  <Button 
                    variant="contained" 
                    color="error"
                    size="small"
                    onClick={handleClose}
                    endIcon={<CancelIcon />}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Typography>
          </Box>
        </Modal>

        {/* Active Events Display */}
        <Modal
          open={active}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModelStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Active Fucking Events
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {current.map((item) => <p>{item}</p>)}
            </Typography>
          </Box>
        </Modal>

      </ThemeProvider>

    </div>
    );
  }

export default App;
