import React, {useState} from 'react';
import {TextField , Button, Box, Grid, Paper} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import AppTheme from './Theme'
// import ChangeHandler from './ChangeHandler';

function EventNav(){

    const mainTheme = AppTheme();
    const [EventSearch, setValue] = useState('');
    const [CreateEvent, triggerCreate] = useState(false);

    return(

        <div id="Nav">
            <ThemeProvider theme={mainTheme}>
                <Box id="Navigation">
                    <Grid 
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid xs={12}>
                            <TextField
                                id="Search_Event_ID"
                                name="Search_Event_ID"
                                label="Event ID"
                                inputProps={{ maxLength: 8 }}
                                variant="standard"
                                margin="normal"
                                size="small"
                                color="primary"
                                value={EventSearch}
                                onChange={event => setValue(event.target.value)}
                            />
                        </Grid>
                        <Grid>
                            <Button 
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    console.log({EventSearch});
                                    setValue('');
                                  }}
                                endIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Grid>
                        <Grid>
                            <Button 
                                variant="contained"
                                onClick={() => {
                                    triggerCreate(true);
                                  }}
                                endIcon={<AddIcon />}>
                                Create New Event
                                
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
        </div>

    );

}

export default EventNav;