import { createTheme } from '@mui/material/styles';

function AppTheme(){

    const mainTheme = createTheme({
        palette: {
          primary: {
            light: '#ffad42',
            main: '#f57c00',
            dark: '#bb4d00',
            contrastText: '#000000'
          },
          secondary: {
            light: '#212121',
            main: '#484848',
            dark: '#000000',
            contrastText: '#FFFFFF'
          },
        },
    });

    return mainTheme
}

export default AppTheme