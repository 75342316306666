// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set } from "firebase/database";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqjJKICWBHEQYL66m2jv4IGmKONQrSzRs",
  authDomain: "thefuckingtrip.firebaseapp.com",
  databaseURL: "https://thefuckingtrip-default-rtdb.firebaseio.com",
  projectId: "thefuckingtrip",
  storageBucket: "thefuckingtrip.appspot.com",
  messagingSenderId: "692973032362",
  appId: "1:692973032362:web:da43c6b7ced431ef6b11d3",
  measurementId: "G-0KZ602F3DJ"
};

// Initialize Firebase

// const analytics = getAnalytics(app);
// const app = initializeApp(firebaseConfig);
// export const database = getDatabase(app);

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getDatabase(app);
